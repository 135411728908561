import React, {useMemo, useState} from 'react';
import ReactDOM from 'react-dom/client';

// @ts-ignore
import txt from "bundle-text:./text.txt";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Button, Card, CardActions,
  CardContent,
  Chip,
  Container,
  createTheme,
  CssBaseline, Divider, Link,
  List, ListItem,
  Modal,
  Paper,
  Stack,
  ThemeProvider,
  Typography
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

type AnnotationType = "symbol" | "setting" | "theme" | "character" | "plot";

type Annotation = {
  range: [number, number];
  type: AnnotationType,
  name: string,
  note?: string,
  id: number,

  lineNum: number,
  para: Paragraph,
  sub?: Annotation[];
}

type Paragraph = {
  text: string;

  lineNum: number,
  annotations: Annotation[];
  chap: Chapter;
}

type Chapter = {
  title: string;
  id: number;
  content: Paragraph[];
}

function parseText() {
  //create virtual node with innerHTML txt
  const element = document.createElement('div');
  element.innerHTML = txt;
  let chaps: Chapter[] = [];
  let numAnnotations=0;

  for (let child of element.children) {
    chaps.push({title: "???", content: [], id: chaps.length});
    let chap: Chapter = chaps[chaps.length-1];
    let numLines=0;
    
    for (let child2 of child.children) {
      if (child2.textContent===null) continue;

      if (child2.tagName=="H2") {
        chap.title = child2.textContent;
      } else if (child2.tagName=="P") {
        let txt = child2.textContent;
        let last_idx = 0;

        chap.content.push({text: "", annotations: [], chap: chap, lineNum: numLines});
        let para: Paragraph = chap.content[chap.content.length-1];

        let addNumLines = (str: string) => {
          numLines+=str.match(/\n/g)?.length ?? 0;
          para.text += str;
        };

        for (let m of txt.matchAll(/(?<!\\)\(([^]*?)(?<!\\);([^]*?)(?<!\\)\)/g)) {
          if (m.index==undefined) continue;

          addNumLines(txt.slice(last_idx, m.index));

          let arr = m[1].split(',');
          para.annotations.push({range: [para.text.length, para.text.length+m[2].length], type: arr[
0].trim() as AnnotationType, name: arr[1].trim(), note: arr.length>2 ? arr.slice(2).join(",").replaceAll("\\;", ";") : undefined, id: numAnnotations, para: para, lineNum: numLines});
          numAnnotations++;

          addNumLines(m[2]);

          last_idx=m.index + m[0].length;
        }

        addNumLines(txt.slice(last_idx));
      }
    }
  }

  return chaps;
}

function Paragraph({paragraph: {lineNum, annotations, text: txt}, openAnnotation}: {paragraph: Paragraph, openAnnotation: (a: Annotation) => void}) {
  let last_idx = 0;
  //show line number in greyed out text as first element
  let elements: JSX.Element[] = [
    <Typography variant="caption" key={"lineNum"} className="lineNum" >line {lineNum}</Typography>
  ];

  for (let annotation of annotations) {
    elements.push(<Typography variant="body1" key={last_idx} dangerouslySetInnerHTML = {{__html: txt.slice(last_idx, annotation.range[0])}}></Typography>);

    if (annotation.range[1]==annotation.range[0]) {
      elements.push(<a key={`empty${annotation.range[0]}`} id={`annotation${annotation.id}`} ></a>)
    } else {
      elements.push(<Link color="inherit" key={annotation.range[0]} onClick={() => openAnnotation(annotation)} ><Typography variant="body1" className={annotation.type} dangerouslySetInnerHTML={{__html: txt.slice(annotation.range[0], annotation.range[1])}} id={`annotation${annotation.id}`} ></Typography></Link>);
    }

    last_idx = annotation.range[1];
  }

  elements.push(<Typography variant="body1" key={last_idx} dangerouslySetInnerHTML={{__html: txt.slice(last_idx)}} ></Typography>);

  return (<>{elements}</>);
}

type AnnotationListProps = {
  type: AnnotationType;
  annotations: Annotation[];
}

let scroll = (a: Annotation) => {
  let el = document.getElementById(`annotation${a.id}`)!;
  el.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
};

function AnnotationList({type, annotations}: AnnotationListProps) {
  let annotations_ty = annotations.filter((x) => x.type==type);

  //deduplicate names
  let kinds = [...new Set(annotations_ty.map((x) => x.name))];

  let elems: JSX.Element[] = [<Typography variant="h2" key={type} >{type}</Typography>];

  for (let kind of kinds) {
    elems.push(<Typography variant="h3" key={kind} >{kind}</Typography>);

    elems=elems.concat(
      annotations_ty
      .filter((x) => x.name==kind)
      .map((annotation) => {
        if (annotation.note) {
          //MUI <Card/> showing note, excerpt from paragraph, and link to scroll
          return (<Card key={annotation.id} >
            <CardContent>
              <Typography variant="subtitle1">{annotation.note}</Typography>
              {annotation.range[1]>annotation.range[0] ? <Box component="blockquote" className="quote" ><Typography variant="body1">{annotation.para.text.slice(annotation.range[0], annotation.range[1])}</Typography></Box> : <></>}
            </CardContent>
            <CardActions>
              <Button onClick={() => scroll(annotation)} >{`Line ${annotation.lineNum}, ${annotation.para.chap.title}`}</Button>
            </CardActions>
          </Card>);
        } else {
          return (<Chip key={annotation.id} label={`Line ${annotation.lineNum}, ${annotation.para.chap.title}`} variant="outlined" onClick={() => scroll(annotation)} />);
        }
      }));
  }

  return (<Box>
    <Stack alignItems={"flex-start"} spacing={2} >
      {elems}
    </Stack>
  </Box>);
}

function PlotOutline({annotation}: {annotation: Annotation}) {
  //hierarchical view of plot outline, recursively invoking PlotOutline element
  //indents each level
  let [collapsed, setCollapsed] = useState(true);

  let card = (<Card>
      <CardContent>
        <Typography variant="subtitle1">{annotation.note}</Typography>
        {annotation.range[1]>annotation.range[0] ? <Box component="blockquote" className="quote" ><Typography variant="body1">{annotation.para.text.slice(annotation.range[0], annotation.range[1])}</Typography></Box> : <></>}
      </CardContent>
      <CardActions>
        <Button onClick={() => scroll(annotation)} >{`Line ${annotation.lineNum}, ${annotation.para.chap.title}`}</Button>
      </CardActions>
    </Card>);

  if (!annotation.sub || annotation.sub.length==0) {
    return card;
  }

  return (<Accordion expanded={!collapsed}>
    <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => {setCollapsed(!collapsed)}} />} >
      {card}
    </AccordionSummary>
    <AccordionDetails>
      <Stack className="outlinebox" >
        {annotation.sub.map((annotation) =>
          (<Box key={annotation.id} >
            <PlotOutline annotation={annotation} />
          </Box>)
        )}
      </Stack>
    </AccordionDetails>
  </Accordion>);
}

function App() {
  let [chaps, all_annotations, all_types, outline] = useMemo(() => {
    let chaps = parseText();
    let all_annotations = chaps.flatMap((x) => x.content).flatMap((x) => x.annotations);
    let plot_annotations = all_annotations.filter((x) => x.type=="plot");

    let outline_plot = (annotation_i: number, prec: number): [Annotation[], number] => {
      let outline: Annotation[] = [];
      while (annotation_i<plot_annotations.length) {
        let n = Number.parseInt(plot_annotations[annotation_i].name);
        if (n<prec) return [outline, annotation_i];
        let [s, i] = outline_plot(annotation_i+1, n+1);
        outline.push({...plot_annotations[annotation_i], sub: s});
        annotation_i=i;
      }

      return [outline, annotation_i];
    };

    let all_types = [...new Set(all_annotations.map((x) => x.type))];
    return [chaps, all_annotations, all_types, outline_plot(0, 0)[0]];
  }, []);
  // chaps = [chaps[0]];
  // console.log(chaps);
  // chaps=[];

  let [annotation, setAnnotation] = useState<null | Annotation>(null);

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  //MUI list of chapters (clicking on a chapter will scroll you to it)
  let chapterList = (
    <List>
      {chaps.map((chap) => {
        let scroll = () => {
          let el = document.getElementById(`chap${chap.id}`)!;
          el.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        };

        return (<ListItem key={chap.id} >
          <Button onClick={scroll} >{chap.title}</Button>
        </ListItem>);
      })}
    </List>
  );

  //show current annotation in modal
  return (<>
    <Modal open={annotation!=null} onClose={() => setAnnotation(null)}
           aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      {annotation!=null ? <Box sx={modalStyle} >
        <Typography id="modal-modal-title" variant="h2" >{annotation.type} - {annotation.name}</Typography>
        <Typography id="modal-modal-description" variant="subtitle1" >{annotation.note}</Typography>
        {annotation.range[1]>annotation.range[0] ? <Box component="blockquote" className="quote" ><Typography variant="body1">{annotation.para.text.slice(annotation.range[0], annotation.range[1])}</Typography></Box> : <></>}
      </Box> : <></>}
    </Modal>

      <Stack direction={"row"} spacing={2} className="mainstack" >
        <Paper className="outline" ><Container>
          <Stack>
            {outline.map((annotation) =>
              (<Box key={annotation.id} >
                <PlotOutline annotation={annotation} />
              </Box>)
            )}
          </Stack>
        </Container></Paper>
        <Paper className="chapters" ><Container>
          <Typography variant="h2" >Frankenmap</Typography>
          <Typography variant="subtitle1" >featuring nonexistent mobile support!</Typography>
          <Typography variant="subtitle1" >text is courtesy of <a style={{color: "inherit"}} href="https://www.gutenberg.org/policy/license.html" >Project Gutenberg</a></Typography>
          {chapterList}
        </Container></Paper>

        <Paper className="text" ><Container>
          <Stack spacing={2} >
            {chaps.map((chap) => {
              return (<Box key={chap.id} >
                <Typography variant="h2" id={`chap${chap.id}`} >{chap.title}</Typography>
                <Stack spacing={2} >
                  {chap.content.map((para) => {
                    return (<Paragraph key={para.lineNum} paragraph={para} openAnnotation={setAnnotation} ></Paragraph>);
                  })}
                </Stack>
              </Box>);
            })}
          </Stack>
        </Container></Paper>

        {
          //make AnnotationLists for all AnnotationTypes
          all_types.filter((ty) => ty!="plot").map((type) => {
            return (<Paper key={type} ><Container><AnnotationList key={type} type={type} annotations={all_annotations} /></Container></Paper>);
          })
        }
      </Stack>
    </>);
}

window.addEventListener('load', () => {
  let theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
});